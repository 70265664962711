<div class="loading-overlay  " *ngIf="loader">
    <div class="spinner"></div>
</div>
<div
    class="container-fluid rounded-2 d-flex justify-content-between align-items-center gap-5 p-lg-5 px-1 py-5 emailContainer"
    *ngIf="data.btnTxt !=='Submit'" [ngClass]=" !thankyou ? 'd-flex' : 'd-none'"
    #formPopUp>
    <div class="col-12 d-flex flex-row">

        <div
            class="col-6 px-5 mx-4 bg-light d-none d-lg-flex justify-content-center align-items-center">
            <img [src]="data.imgUrl" alt
                class="img-fluid d-none d-lg-flex justify-content-center align-items-center"
                style="height: 54% !important" />
        </div>
        <form [formGroup]="form"
            class="col-10 col-lg-4 d-flex flex-column justify-content-start gap-3 text-start mx-auto ms-lg-5">
            <h1 class="mb-3 pb-4 mb-md-0 fs-5">{{ data.heading }}</h1>
            <p class="mb-3 pb-4 mb-md-0 " *ngIf="data.btnTxt =='Submit'">
                Discover how Intercargo Logistics can power your supply chain.
                Enter your details below, and we’ll email
                you our comprehensive brochure.
            </p>
            <div class="col-12 col-md-12 d-flex flex-column flex-lg-row">
                <div class="col-12 col-md-12 col-lg-6 pe-0 pe-lg-2">
                    <label for="firstName" class="form-label">First Name</label>
                    <input type="text" class="form-control py-3" id="firstName"
                        formControlName="firstName" required
                        placeholder="First name" />
                    <div
                        *ngIf="form.get('firstName')?.invalid && isSubmitted"
                        class="text-danger">
                        Please enter your first name.
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-6 ps-0 ps-lg-2">
                    <label for="lastName" class="form-label">Last Name</label>
                    <input type="text" class="form-control py-3" id="lastName"
                        formControlName="lastName" required
                        placeholder="Last name" />
                    <div
                        *ngIf="form.get('lastName')?.invalid && isSubmitted"
                        class="text-danger">
                        Please enter your last name.
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-12">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control py-3" id="email"
                    formControlName="email"
                    placeholder="Enter Your Email Address" required />
                <div
                    *ngIf="form.get('email')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter a valid email.
                </div>
            </div>

            <div class="col-12 col-md-12">
                <label for="companyName" class="form-label">Company Name</label>
                <input type="text" class="form-control py-3" id="companyName"
                    formControlName="companyName"
                    placeholder="Company Name" required />
                <div
                    *ngIf="form.get('companyName')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter your company name.
                </div>
            </div>

            <div class="col-12 col-md-12">
                <label for="phone" class="form-label">Contact Number</label>
                <input type="text" class="form-control py-3" id="phone"
                    formControlName="phone"
                    placeholder="+4467456543" required />
                <div
                    *ngIf="form.get('phone')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter your phone number.
                </div>
            </div>

            <div
                class="col-12 d-flex flex-lg-row flex-column justify-content-center mt-5 gap-lg-0 gap-4">
                <div class="col-12 col-lg-6">
                    <button type="button"
                        class="btn btn-outline-primary w-100 me-lg-2"
                        (click)="onCloseClick({ status: false, message: '' })">
                        Cancel
                    </button>
                </div>
                <div class="col-12 col-lg-6">
                    <button (click)="onBtnClick()"
                        class="btn btn-secondary w-100 ms-lg-2">
                        {{ data.btnTxt }}
                    </button>
                </div>
            </div>
        </form>

    </div>

</div>

<div class="container-fluid gap-5 p-lg-5 px-1 py-4 emailContainer"
    *ngIf="data.btnTxt === 'Submit'"
    [ngClass]=" !thankyou ? '' : 'd-none'"
    style=" height: 80% !important;">
    <div class="p-3 gap-3">
        <h2 class="fs-4">Unlock Your Supply Chain's Potential – Get Our Brochure
            Now!</h2>
        <p>Discover how Intercargo Logistics can power your supply chain. Enter
            your details, and we’ll email you our
            comprehensive brochure.</p>
    </div>
    <form [formGroup]="form" class="gap-5 p-3" (ngSubmit)="onBtnClickForm()">
        <div class="form-row row">
            <div class="form-group col-md-3 py-2">
                <label for="input1" class="form-label">First Name</label>
                <input type="text" class="form-control py-3" id="input1"
                    formControlName="firstName"
                    placeholder="Enter Your First name" />
                <div
                    *ngIf="form.get('firstName')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter your first name.
                </div>
            </div>
            <div class="form-group col-md-3 py-2">
                <label for="input2" class="form-label">Last Name</label>
                <input type="text" class="form-control py-3" id="input2"
                    formControlName="lastName"
                    placeholder="Enter Your Last name" />
                <div
                    *ngIf="form.get('lastName')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter your last name.
                </div>
            </div>
            <div class="form-group col-md-6 py-2">
                <label for="input3" class="form-label">Email</label>
                <input type="email" class="form-control py-3" id="input3"
                    formControlName="email"
                    placeholder="Enter Your Email Address" />
                <div
                    *ngIf="form.get('email')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter a valid email.
                </div>
            </div>
        </div>
        <div class="form-row row">
            <div class="form-group col-md-6 py-2">
                <label for="input4" class="form-label">Company Name</label>
                <input type="text" class="form-control py-3" id="input4"
                    formControlName="companyName"
                    placeholder="Enter Company Name" />
                <div
                    *ngIf="form.get('companyName')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter your company name.
                </div>
            </div>
            <div class="form-group col-md-6 py-2">
                <label for="input5" class="form-label">Contact Number</label>
                <input type="text" class="form-control py-3" id="input5"
                    formControlName="phone"
                    placeholder="+4467456543" />
                <div
                    *ngIf="form.get('phone')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter your contact number.
                </div>
            </div>
        </div>
        <div class="form-row row">
            <div class="form-group col-md-6 py-2">
                <label for="input11" class="form-label">Address Line 1</label>
                <input type="text" class="form-control py-3" id="input11"
                    formControlName="address1"
                    placeholder="Address Line 1" />
                <div
                    *ngIf="form.get('address1')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter your address.
                </div>
            </div>
            <div class="form-group col-md-6 py-2">
                <label for="input12" class="form-label">Address Line 2</label>
                <input type="text" class="form-control py-3" id="input12"
                    formControlName="address2"
                    placeholder="Address Line 2" />
                <div
                    *ngIf="form.get('address2')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter your address.
                </div>
            </div>
        </div>
        <div class="form-row row">
            <div class="form-group col-md-3 py-2">
                <label for="country" class="form-label">Country</label>
                <select id="country" class="form-select"
                    formControlName="country" required>
                    <option value>Select Country</option>
                    <option *ngFor="let country of countries"
                        [value]="country.code">{{ country.name }}</option>
                </select>
                <div
                    *ngIf="form.get('country')?.invalid && isSubmitted"
                    class="text-danger">
                    Please select your country.
                </div>
            </div>
            <div class="form-group col-md-3 py-2">
                <label for="city" class="form-label">City</label>
                <select id="city" class="form-select" formControlName="city"
                    required>
                    <option value *ngIf="loadingCities">Loading
                        Cities...</option>
                    <option value
                        *ngIf="!loadingCities && cities.length === 0">Select
                        City</option>
                    <option *ngFor="let city of cities" [ngValue]="city">{{
                        city.name
                        }}</option>
                </select>
                <div
                    *ngIf="form.get('city')?.invalid && isSubmitted"
                    class="text-danger">
                    Please select your city.
                </div>
            </div>
            <div class="form-group col-md-6 py-2">
                <label for="input6" class="form-label">Postal Code</label>
                <input type="text" class="form-control py-3" id="input6"
                    formControlName="postCode"
                    placeholder="Provide Post code" />
                <div
                    *ngIf="form.get('postCode')?.invalid && isSubmitted"
                    class="text-danger">
                    Please enter your postal code.
                </div>
            </div>
        </div>

        <div
            class="col-12 d-flex flex-lg-row flex-column justify-content-center mt-5 gap-lg-0 gap-4">
            <div class="col-12 col-lg-6">
                <button type="button"
                    class="btn btn-outline-primary w-100 me-lg-2"
                    (click)="onCloseClick({ status: false, message: '' })">
                    Cancel
                </button>
            </div>
            <div class="col-12 col-lg-6">
                <button type="submit" class="btn btn-secondary w-100 ms-lg-2">
                    {{ data.btnTxt }}
                </button>
            </div>

        </div>
    </form>

</div>

<!-- <div class="container-fluid rounded-2 d-flex justify-content-between align-items-center gap-5 p-5 emailContainer iframe-container"
    style="overflow-y: scroll !important;" [ngClass]="!thankyou ? 'd-flex' : 'd-none'" *ngIf="data.btnTxt != 'Submit'">

  
    <div class="close-icon" (click)="onCloseClick({ status: false, message: '' })">
        <i class="bi bi-x-circle fs-4"></i>
    </div>
    <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(IframeString)" frameborder="0"></iframe>

</div> -->

<div
    class="container-fluid d-flex flex-column justify-content-center align-items-center gap-3 p-5 d-none  text-center emailContainer "
    #thanksPopUp>

    <h1 class="text-primary lh-1" [innerHTML]="data.thanksHeading"></h1>
    <p class="fs-6 text-center " [innerHTML]="data.thanksTxt">
    </p>
    <div
        class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center mt-2 gap-lg-0 gap-4">

        <button type="button"
            class="btn btn-outline-primary rounded-circle d-flex align-items-center justify-content-center"
            (click)="onCloseClick({ status: false, message: '' })">
            <i class="bi bi-check fs-3"></i>
        </button>

    </div>
</div>